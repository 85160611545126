.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 /* New CSS added*/ 


 .bg-secondary{
  background-color: #273746; 
}
.row-header {
  margin: 0px auto;
  padding: 0px 0px;
  color: #000;
}

.intro h2::first-letter {
  font-size: 200%;
  color: rgb(107, 91, 149);
  color: #ffde88;
}
.lebensW{

  color:#000;
  color: #cc172a;
} 
.lebensS{
  color: #CD5C5C;
  color: #Ecbe45;
}
.intro{
  margin: 40px 0px 0px -60px;
  padding:80px 50px 50px 10px;
}

.hide-hero1{
  display: none;
}

.intro h1{
    font-size: 2rem;
    line-height: 2.5rem;
    color: #3e4444;
    display: inline;
    font-weight: bold;
}
.newbg{
  background: #F4F6F7;
}

.services-page .img-fluid 
{
    max-width: 90%;
}

.services-page h1{
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  color: #3e4444;

  padding: 10px;
    border-left: 6px solid #CD5C5C;
    border-right: 6px solid #CD5C5C;
    border-radius: 5px;
    background-color: #fbfbfb;
}


.service-block{
  box-sizing: border-box;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
  background: #fff;
    border-radius: 15px;
    border-left: 5px solid #fff;
    border-right: 5px solid #fff;
    box-shadow: 0 0 1rem 0  rgb(0 0 0 / 25%);

}

.service-offered .grid_item{
  background-color: #fff;
}
.intro h2{

  font-size: 1.75rem;
  text-shadow: #000 1px 4px 10px;
  display: inline;
  /* background-color: #334047!important; */

}

.intro h2 a{

  font-size: 1.5rem!important;
  text-decoration: none!important;
  background: none!important;

}

.intro p{
  margin-top: 20px;
  font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.5rem;
    color: #333;

    font-size: .76471rem;
    font-weight: 500;
    letter-spacing: .05882rem;
}
/* 
border-bottom: 1px ridge;  */
.main-nav img{
  width: 150px;
}
.navbar-brand{
  padding-bottom: 0rem;
}
.row-content {
  margin: 0px auto;
  padding: 10px 0px;
  min-height: 400px; }

  .footer-content {
    margin: 0px auto;
    padding:5px 30px 10px 30px;
    min-height: 230px; }
.socialblock {
  /* height: 200px;  */
  padding: 20px 30px;
  box-sizing: border-box;
  width: 80%;
  margin: 0 10%;
  position: relative;
}

.jumbotron {
  margin: 0px auto;
  border: none;
  border-radius: 0;
  padding: 70px 30px;
background: none;
  padding: 35px 30px 30px 30px;
  color: floralwhite; 
  position: relative;
  min-height: 550px;
}


  .downarrow{ 
    position: relative;
    float: left;
    left: 10%;
    top:10%;
  }

  .logo i{
    color: #6b5b95;
}
.container-fluid{
  padding-right: 0px;
    padding-left: 0px;
    overflow: hidden;
}
  .discount{
  background-color: #deeaee;
  background: #ffde88;
  background: rgb(2,0,36);
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,181,248,1) 0%, rgba(0,212,255,1) 100%, rgba(0,212,255,1) 100%, rgba(0,212,255,1) 100%);
}
  .domain-expert{

    /* background: linear-gradient(
120deg
,#001c24,#020e1a); */
background-color: #deeaee;
background: #00B5F8;
min-height: auto;
  }
#discount-block h2{
   
    letter-spacing: normal;
    line-height: 2.94118rem;
    font-size: 33px;
    font-weight: bolder;
   
    }

   
.industry-grid{
  display: flex;
    flex-direction: column;
    padding: 10px 24px;
    background: #fff;
    margin: 0px auto;
    width: 800px;
    border-radius: 4px;
    box-shadow: 0 8px 26px 0 rgb(0 0 0 / 8%);
    background-color: #fff;
}

.domain-grid{
  display: flex;
    flex-direction: column;
    padding: 20px 24px;
    margin: 0px auto;
   
  
}
.domain-grid .grid_item {
  display: flex;
    flex-direction: row; 
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    width: 240px;
    height: auto;
    background-color: #fff;
    box-shadow: 0 0.25rem 3.3rem rgb(15 87 207 / 10%);
    border-radius: .5rem;
    margin:10px;
    padding: 20px 18px 20px 18px !important;
}


.domain-grid .grid_text{
 
    margin-top: 0px;
    margin-left: 10px;
    font-size: 13px;
    line-height: 16px;
    color: #212121;
    text-align: center;
    text-transform: capitalize;
    font-size: 1.1rem;
    font-weight: 600;
    width: 13rem;
    white-space: normal;

}
.domain-grid  .grid_img{
  width: 50px;
    height: 32px;
}

.faq-grid{
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  margin: 0px auto;
   
  
}
.faq-grid .grid_item {
    flex-direction: row; 
    justify-content: space-between;
    width: auto;
    height: auto;
    background-color: #fff;
    box-shadow: 0 0.25rem 3.3rem rgb(15 87 207 / 10%);
    border-radius: .5rem;
    margin:10px;
    padding: 20px 18px 20px 18px !important;
}


.faq-grid .grid_text{
 
    margin-top: 0px;
    margin-left: 10px;
    font-size: 13px;
    line-height: 16px;
    color: #212121;
    text-align: center;
    text-transform: capitalize;
    font-size: 1.1rem;
    font-weight: 600;
    white-space: normal;

}
.faq-grid  .grid_img{
display: flex;
}

.accordion-content{
  display: flex;
  flex-direction: row;
    height: auto;
    justify-content: center;
   
}

.accordion-content div{
  background-color: #fff;
  box-shadow: 0 0.25rem 3.3rem rgb(15 87 207 / 10%);
  border-radius: 0.5rem;
  margin: 10px;
  padding: 20px 18px 20px 18px !important;
  display: flex;
  flex-direction: row;
  max-width: 800px;
}

.ams-grid .grid_row{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.ams-grid .grid_item{
  width: fit-content;
  
}

.grid_row{
  display: flex;
    flex-direction: row;
    justify-content: center;
}

.grid_item{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  height: 112px;
  cursor: pointer;
  padding: 24px 16px 16px;
  overflow: hidden;
}
.grid_img{
  width: 32px;
    height: 32px;
}

.services .grid_img{
  width: 45px;
    height: 45px;
}

.services .grid_item {
  width: auto;
  height: auto;
  padding: 70px 50px;
  margin: 5px;
  position: relative;
}

.services .grid_item:hover{
  -webkit-box-shadow: -1px 1px 42px -7px rgba(0,0,0,1);
-moz-box-shadow: -1px 1px 42px -7px rgba(0,0,0,1);
box-shadow: -1px 1px 42px -7px rgba(0,0,0,1);
}

.services .grid_item:hover .overlay {
  bottom: 0;
  height: 100%;
}

.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #ffde88;
  overflow: hidden;
  width: 100%;
  height:0;
  transition: .5s ease;
}

.text {
  padding: 15px 20px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #3e4444;
  letter-spacing: 1px;

  /* position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  text-align: left;
}

.text p:nth-child(2) {
  font-size: 12px;
  color: #000;
}

.services .industry-grid{
  width: auto;
  background: none;
}
.services .grid_text{
  font-size: 20px;
  letter-spacing: normal;
    line-height: 2.94118rem;
    font-weight: bolder;
    color: #3e4444;
}


.main-intro-block{
  color: #fff;
  padding: 3rem 7rem;
  min-height: 450px;
  background-color: #334047;
  position: relative;
}

.main-intro-block::before {   
  content: "";
  width: 100%;
  height: 54px;
  position: absolute;
  bottom: -0.3%;
  left: 0;
  background-size: auto;
  background-repeat: repeat no-repeat;
  background-position: 10vw bottom;
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  113' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 83L50 78C100 73 200 63 300 42C400 22 500 -9 600 2C700 12 800 63 900 83C1000 104 1100 93 1150 88L1200 83V114H1150C1100 114 1000 114 900 114C800 114 700 114 600 114C500 114 400 114 300 114C200 114 100 114 50 114H0V83Z' fill='%23ffffff'/></svg>");
}

@media(max-width:850px) {
  .main-intro-block::before {    
    height: 28px
  }  
}


.main-intro-block h5{
  text-align: center;
}
.main-intro-block h5 {
  font-size: .8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 2rem;
    color: #006DE9;
}
.main-intro-block p{
  color: #3e4444;
}
.main-intro-block a, .ams-section a{
  font-size: 1.1rem;
  line-height: 1.2;
  background: #00B5F8;
  font-weight: 600;
  color: #000;
  border-width: 0.5px;
  border-radius: 4px;
}
.main-heading{
  letter-spacing: normal;
    line-height: 2.94118rem;
    font-size: 33px;
    padding: 5px 0 8px;
    position: relative;
}

.word {
  /* font-size:2em;
  font-weight:700;
  opacity:0;
  position: absolute; */
  /* width: 100%;
  text-align: center; */
  font-weight: bolder;
  color: #ffd66c;
  /* background: -webkit-linear-gradient(36deg, #BB0066, #BB0066);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
/* background-color: #566cec;
background-image: linear-gradient(
  90deg,#14CBA7,#566cec 25%,#14CBA7 75%,#ff7c51);
background: -webkit-linear-gradient(36deg, #BB0066, #FFCF00);
background: -webkit-linear-gradient(36deg, #ffba98, #FFCF00); 
background: linear-gradient(128deg, rgba(2,0,36,1) 0%, rgba(235,152,78,1) 0%, rgba(255,222,136,1) 100%); */

.services{
  background:#334047;
  color: #fff;
  min-height: 530px;
}
.services .industry-grid{
  box-shadow: none;
}
.services #discount-block h5{
  color: #ffde88;
}
.services .services-keywords{
  margin-top: .58824rem;
  font-weight: 400;
  line-height: 1.58824rem;
  font-size: 14px;
  letter-spacing: 0;
  color: #000;
  /* color:#ffde88; */
  
}
.grid_text{
  display: inline-block;
    margin-top: 16px;
    font-size: 13px;
    line-height: 16px;
    color: #212121;
    text-align: center;
    text-transform: capitalize;

}

#reservebtn a {
  font-size: 1.1rem;
  line-height: 1.2;
  background: #deeaee;
  background: #00B5F8;
  font-weight: 600;
  color: #000;
  /* border: 1px solid; */
  border-radius: 0%;
  border-width: 0.5px;
  border-radius: 4px;
}

.discount-block h2{
  margin-bottom: 0%!important;
}

#discount-block h5{
 
    font-size: .9rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #3e4444;
    letter-spacing: 1px;

}

.fitplans{
  padding: 3rem 7rem;
}

.fitplans h2{
  padding: 5px 0 8px;
  position: relative;
}

/* .fitplans h2::before {
  content: "";
  position: absolute;
  bottom: 15px;
  width: 150px;
  height: 10px;
  transform: skew( 
-12deg
) translateX(-50%);
  background: rgb(59 112 125 / 55%);
} */

.deals h3{
  line-height: 1.5;
  color: #233143;
    font-weight: 500;
 /* text-decoration: underline;
   
    text-decoration: underline wavy #ffd66c 5px;
   */
}
.discount-block-img{
  padding: 50px;
}

#discount-block a{
  background-color: #fff;
  font-weight: 600;
  color: #000;
  padding: .2rem 0.8rem;
  border-radius: 0%;
  border-width: 0.5px;
}

.ams-section{
  background: #334047;
  color: #fff; 
  min-height: 300px;
  padding: 3rem 7rem;
}

.ecom-account-mgmt{
  background-image: url("/src/assets/ecomaccmgmt.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.ecom-ams{
  background-image: url("/src/assets/ecomgmt.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hr-section{
  background-image: url("/src/assets/forhire.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.ams-section h2{
  letter-spacing: normal;
    line-height: 2.94118rem;
    font-size: 30px;
    font-weight: bolder;
    margin: 10px 10px;
    position: relative;
}
.ams-section h5{
  font-size: .9rem;
    font-weight: 600;
    color: #3e4444;
    color: #fff;
    letter-spacing: 1px;
    margin: 20px 0px;
    padding: 20px;
}

.ams-section p{
  font-size: 0.9rem;
}

.ams-section2{
  padding: 3rem 7rem;
}
.ams-section2 h5{
  font-size: .9rem;
    font-weight: 600;
    color: #3e4444;
    letter-spacing: 1px;
    margin: 20px 0px;
    padding: 10px;
}

.Hcs .btn{
  background-color: #fff;
}

.mrc-section {
  background: #fff;
  color: #334047;
}

.mrc-content{
  border-bottom: 1px solid #000;
}
.mrc-section h5{

  color: #334047;
}
/* .services{
  background-color: #F3F4F7;
} */
.equipment{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.8); /* Black background with 0.5 opacity */
  color: white;
  padding: 20px;
}
.equipment:hover{
 
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */

}
 .underline-text a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  top: 5px;
  left: 50%;
  position: relative;
  background: #000;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.underline-text  a:hover:after { 
  width: 100%; 
  left: 0; 
}
address {
  font-size: 80%;
  margin: 0px;
  color: #969696; }
  .socialblock h2{

    text-align: center;
  }
.phonebook span{
  padding:0px;
  display: block;
}
.phonebook a {
  background:none!important;
  color: #000;
  font-size: 18px;
  font-weight: normal!important; 
  text-decoration:none;
}
.deals a{
  font-size: 13px!important;
}
body {
  padding: 0px 0px 0px 0px;
  z-index: 0; }

  .header-top_area{
    display: block;
  }

  .header-area .header-top_area {
    padding: 6px 0;
}

.header-area .header-top_area .short_contact_list {
  text-align: center;
}
.header-area .header-top_area .social_media_links {
  text-align: center;
}
.header-area .header-top_area .short_contact_list ul {
  margin-bottom: 0rem;

}
.header-area .header-top_area .short_contact_list ul li {

  display: inline-block;
}
.header-area .header-top_area .short_contact_list ul li a {
  font-size: 14px;
  color: #222222;
  margin-right: 40px;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
}
.header-area .header-top_area .short_contact_list ul li a i {
  margin-right: 10px;
  font-size: 20px;
}
.header-area .header-top_area .social_media_links a {
  font-size: 15px;
  color: #222222;
  margin-right: 12px;
  text-decoration: none;
}

  .header-area {
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    z-index: 9;
    background: #00B5F8; 
    border-bottom: 1px solid #e4e7ed;
}
/* .navbar{
  background: #9575CD;
   
   
} */
#Navbar span{ 
  justify-content: end;
}
.navbar-brand {
  padding-top: .4625rem;
  font-size: 28px;
  font-weight: 600; }
  
.navbar-inverse {
  background-color: #512DA8; }

.navbar-dark {
  background: #512DA8; }


.navbar-nav .nav-link {
  margin-right: 25px;
  margin-top: 12px;
  color: #333;
  padding: 0;
  background: none;
  text-transform: capitalize;
  font-family: 'MarkPro-Medium',sans-serif;
    line-height: 3.76471rem;
    font-size: .80rem;
    font-weight: 500;
    letter-spacing: .05882rem;
}

  .navbar-nav{
    margin: 10px 0px 10px 60px;

  }

.navbar-custom {
  z-index: 100;
  color: #fff; 
  box-shadow: 5px 3px 5px 0 rgb(0 0 0 / 25%), -8px -8px 12px 0 hsl(0deg 0% 100% / 30%);
  padding: 0px;
}
  .navbar-custom .navbar-brand {
    color:#3e4444;
  
    }
  .navbar-custom .navbar-text {
    background: none;
    padding: 7px 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    text-decoration: none; }

  .navbar-custom .join-now {
    color: #fff;
    border: 1px solid;
    border-width: 0.5px;

  }

  .nav-item .dropdown-menu{ display: none; }

	.nav-item:hover .dropdown-menu{ display: block; }

  .dropdown-menu .divider {
    border-top: 3px solid #bbb;
  }

.posty:hover{ 
  text-decoration:none;
  color: #000;
}
.fa-home{
  color:#85929E ;
}
.tab-content {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px; }


.realestates{
  background-color: #8d9db6;
  -webkit-box-shadow: -1px 0px 32px -9px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 0px 32px -9px rgba(0,0,0,0.75);
box-shadow: -1px 0px 32px -9px rgba(0,0,0,0.75);
}
.realestate .opening-time {
  padding-left: 0; }
  .realestate  .opening-time li {
    list-style: none; }
    .realestate  .opening-time li span {
      position: relative;
    float: left;
    padding-right: 10px;}
      .realestate  .opening-time li span .fa-times {
        color: #ECF0F1 ; }
        .realestate  .opening-time li strong {
      color:#212529 ; }

      .propServices{
        min-height: 300px;
      }
      .propServices h1, .lfr h1{
        padding: 10px;
        padding-bottom: 0%;
        font-size: 1.7rem;
        margin-top: 20px;
        letter-spacing: normal;
    line-height: 2.94118rem;
    font-weight: bolder;
      }
.propServices h1, .fitplans h1, .gymtiming h1{
  color: #000;
}
.open-time{
  padding: 20px 30px 20px 30px;
}
.gym-address{
 
    padding-bottom: 15px;
  
}
 .socialblock h2{
   display: block;
 }
 #socialbtn {
   position: relative;
   top: 20%;
 }
#socialbtn .btn {
  background: #fff;
  color: #000;
  margin: 10px; }

  .btn-social-icon.btn-lg {
   font-size: 15px;
   }

#socialbtn .btn-instagram:hover {
  background: #C13584;
  color: #fff; }

#socialbtn .btn-twitter:hover {
  background: #00acee;
  color: #fff; }

#socialbtn .btn-facebook:hover {
  background: #3b5998;
  color: #fff; }

.carousel-nav-icon {
  height: 48px;
  width: 48px; }

.carousel-item .col, .carousel-item .col-sm, .carousel-item .col-md {
  margin: 8px;
  height: 260px;
  background-size: cover;
  background-position: center center; }

  .contact-section h1{
    font-size: 1.5rem;
  }
  .contact-heading h1{
    font-size: 2.5rem;
  }

  .prop-desc{
    background:#F0F3F4!important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .mainhero{
    /* -webkit-box-shadow: -1px 0px 32px 0px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 0px 32px 0px rgba(0,0,0,0.75);
box-shadow: -1px 0px 32px 0px rgba(0,0,0,0.75);

-webkit-box-shadow: 0 9px 20px rgb(13 38 76 / 10%);
box-shadow: 0 9px 20px rgb(13 38 76 / 10%); */
-webkit-box-shadow: -1px 2px 34px 21px rgba(242,242,242,1);
-moz-box-shadow: -1px 2px 34px 21px rgba(242,242,242,1);
box-shadow: -1px 2px 34px 21px rgba(242,242,242,1);


  } 
  #specs{
    background-color: #8d9db6;
    margin: 50px 25px 25px 25px;
    -webkit-box-shadow: -1px 0px 32px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 0px 32px 0px rgba(0,0,0,0.75);
    box-shadow: -1px 0px 32px 0px rgba(0,0,0,0.75);

  }
  #specs .fas{
    padding: 20px;
    font-size: 40px;
   color: #ECF0F1;

  }
.carousel-item .badge-danger {
  background: #48C9B0;
  color: black;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .3rem; }
  .pride h1 {
    text-align: center;
    position: relative;
    top: 40%;
}
.pain h1{
  text-align: center;
  position: relative;
  top: 40%;
}
.middle .below{
  text-align: center;
}
  .posty{
    text-decoration:none;
    color: #000;
    padding: 10px;
    margin: 10px;
    
  }
.Institutions .bd-highlight{


  background: #ffde88; 
 

  
}



.modal-content .modal-header {
  background: #48C9B0;
  color: floralwhite;
  border-bottom: none; }
  .modal-content .modal-header .close {
    color: floralwhite; }

.modal-content .modal-body {
  background: #D1C4E9; }

.modal-content .modal-footer {
  background: #9575CD;
  border-top: none; }

footer {
  background-color: #fff;
  background-color: #334047;
  color: #F4F6F6;
  padding: 0px 0 0px 0px;
  font-size: 1rem;
  line-height: 1.5; }
  footer h2{
    font-size: 1.6rem;
   
  }
  footer p{
    font-size: 0.8rem;
  }
  footer h3{
    font-size: 1.1rem;
    color: #ffd66c;
  }
  footer .footer-widget h2 {
    margin-bottom: 30px; }
  footer .footer-widget ul {
    padding-left: 0; }
    footer .footer-widget ul li a {
      color: black; }
  footer .contact-link {
    display: inline-block;
    width: 100%;
    color: #F4F6F6; 
  text-decoration: none;}
  footer .footer-social ul {
    padding-left: 0; }
    footer .footer-social ul li {
      list-style: none;
      float: left;
      padding: 0 10px; }
      footer .footer-social ul li:first-child {
        padding-left: 0; }
      footer .footer-social ul li a {
        font-size: 20px;
        color: #333; }
        footer .footer-social ul li a:hover {
          color: #FF7F57; }

  footer .opening-time {
    padding-left: 0; }
    footer .opening-time li {
      list-style: none; }
      footer .opening-time li span {
        float: left;
        padding-right: 10px; }
        footer .opening-time li span .fa-times {
          color: #48C9B0; }
      footer .opening-time li strong {
        color: #F0F3F4; }
  footer .media-body a {
    color: #333; }
    footer .media-body a:hover {
      color: #FF7F57; }
  footer .media-body span {
    color: #969696; }
  footer .images-gellary ul {
    padding-left: 0; }
    footer .images-gellary ul li {
      list-style: none;
      float: left;
      margin: 0 3% 2% 0;
      width: 31%;
      position: relative; }
      footer .images-gellary ul li:nth-child(3n) {
        margin: 0 0 1%; }

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none !important; }

  /* .gymhour{
    -webkit-box-shadow: -9px 8px 35px 1px rgba(214,219,223,1);
    -moz-box-shadow: -9px 8px 35px 1px rgba(214,219,223,1);
    box-shadow: -9px 8px 35px 1px rgba(214,219,223,1);
  } */


.footer-nav a {
  display: inline;
  background-color: none!important;
}
.footer-nav i{
  
    color: #fff;

}
 .opening-time{
   margin-bottom: 0%;
 }
 

.gymhour h3{
  margin-bottom: 0%;
}
 .opening-time p{
  margin-bottom: 0%!important;
}


.pdf {
  width: 100%;
  aspect-ratio: 4 / 3;
}

.contact{
  min-height: 100px;

  text-align: center;
 
  background-color: #fff;
}
.clientNum{
  min-height: 200px;
  background-color: #fff;
}
.hr{
  height: 1px;
  background: #ebebeb;
  overflow: hidden;
  margin: 55px 0;
}


.projectnum {
  max-width: 990px;
  min-width: 320px;
  margin: auto;
  padding: 0 15px;

  animation-duration: 0.5s;
  animation-name: fadeInUp;
}

.main-total-box {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
text-align: center;
}
.main-total-box div {
width: 33.33%;
padding: 0 100px;
color: #707070;
}

.main-total-box div strong {
font: 400 32px/32px Gilroy-SemiBold,sans-serif;
display: block;
color: #0a0a0a;
margin-bottom: 3px;
}

#whatsapp-block{
display: flex;
  margin: auto;
  justify-content: center;
}

#whatsapp-block img{
  width: 199px;
  }
  

#whatsapp-block h2 {
letter-spacing: normal;
line-height: 2.9rem;
font-size: 25px;
font-weight: 500;
margin-top: 7px!important;
}


#whatsapp-block a {

font-size: 30px;

}

.fa-whatsapp{
color: #25D366;
}
.fa-linkedin{
color: #0077b5;
}
.facebook{
  background-color: #20599F; display: inline-block;    border-radius: 20px; padding: 8px 18px;
}
.facebook a{
  color: #fff;
                                       
                                        font-size: 18px;
                                        font-weight: 400;
                                        text-align: center;
                                        text-decoration: none;
                                        transition: all 0.3s ease 0s;
                                        width: 100%;
                                     
}
.instagram{
  background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
   display: inline-block;    border-radius: 20px; padding: 8px 18px;
}
.instagram a{
  color: #fff;
                                          
                                        font-size: 18px;
                                        font-weight: 400;
                                        text-align: center;
                                        text-decoration: none;
                                        transition: all 0.3s ease 0s;
                                        width: 100%;
                                        border-radius: 10px;
}
.twitter{
  background-color: #1DA1F2; display: inline-block;    border-radius: 20px; padding: 8px 18px;
}

.twitter a{
  color: #fff;
                           
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  width: 100%;
  border-radius: 10px;
}
.fa-facebook{
color: #4267B2;
}


.bd-highlight{
margin: 10px;
  width: 100px;
}

.deals h5{
font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #3e4444;
  letter-spacing: 1px;
}

.bbottom{
border-bottom: 2px solid rgb(59 112 125 / 55%);

   

}

.btop{
  border-top: 2px solid rgb(59 112 125 / 55%);;
  /* border-top: 2px solid #D2E3E9; */
}
.tel{
margin: auto;
text-transform: none!important;
}
.lfr{
padding: 0rem 7rem;
min-height: 230px;
}




/* Responsive media queries */

@media (max-width:1024px) {
  .services .certificates{
    padding: 5px;
  }
  
}

@media (min-width: 1200px) and (max-width: 1800px) { 

    .jumbotron .Aboutcomp{
      position: relative;
    bottom: -25px;
    }
    .services-page img{
      padding: 10px ; 
    }
    .GetInTouch{
      
      margin-left: 200px;
    }
    .header-area{
      
padding: 0 100px 0px 100px;
    }
    
     }
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .services-page img{
    padding: 30px 30px 30px 30px;
  }

  .jumbotron .Aboutcomp{
    position: relative;
  bottom: -55px;
  }
  
.intro{
  padding: 30px 40px 0px 10px;
}
#socialbtn {
  position: relative;
  top: 10%;
}


 }

 @media (max-width: 991.98px) { 
   .industry-grid{
     width: auto;
   }
   .jumbotron .Aboutcomp{
    font-size: 0.9rem;
    position: relative;
  bottom: -75px;
  }
  }

@media (min-width: 768px) and (max-width: 991.98px) { 

  .fitplans {
    padding: 3rem 2rem;
}
  .services-page img{
    padding: 20px 20px 20px 20px;
   
  } 
  .pride h1 {
    font-size: 2.1rem;
    top: 35%;
}
.pain h1 {
  font-size: 2.1rem;
  top: 35%;
}

  .open-time {
    padding: 10px 0px 20px 0px;
}
.intro{
  padding: 10px 40px 0px 10px;
  margin: 40px 0 0 0;
}
.mainhero {
  margin-top: 40px;
}
.jumbotron .container{
  padding: 0px;
 }

.navbar-brand {
  font-size: 30px;
  font-weight: bolder;
}
.navbar-nav{
  margin: 0px 0px 0px 10px;

}
.navbar-nav .fa , .fas{
  display: none;
}
.navbar-nav .fas{
  display: none;
}
 }

@media (min-width: 576px) and (max-width: 767.98px) { 
  .main-total-box div {
    width: 100%;
    padding: 10px;
}
.services .grid_text {
  
  line-height: 1.5rem;

}
  .main-total-box div strong {
    font: 600 23px/23px Gilroy-SemiBold,sans-serif;
}
  .services-page img{
    padding: 30px 30px 30px 30px;
  }
  .fitplans {
   padding: 3rem 1rem; 
}
  .footer-content {
    margin: 0px auto;
    padding: 25px 20px;
    min-height: 230px;
}
  .intro {
    padding: 5px;
    margin: 40px 0px 0px 20px;
}
.intro h1 {
  font-size: 1.2rem;
  text-shadow: #000 1px 4px 10px;
 
}
.intro-p-span{
  display: none;
}
  .navbar-brand {
    font-size: 30px;
    font-weight: bolder;
    margin-right: 0rem;
   }
 .navbar .container{
   padding: 0px;
 }
 .navbar-nav span{
         display: none;
 }
 .jumbotron {
   padding: 15px 10px 20px 10px;
 }
 .jumbotron .container{
  padding: 0px;
 }
 #bgimg h2{
 font-size: 18px;
 }
 #bgimg p{
  font-size: 13px;
  }
  #socialbtn {
    position: relative;
    top: 5%;
}
#reservebtn a {
  font-size: 1rem;
  line-height: 1.2;
}
.mainhero{
  margin-top: 10px;
}
.discount-block-img {
  padding: 0px;
}
.equipment {
  font-size: 15px;
  padding: 10px;
}
.fitplans h1{
 font-size: 25px;
}
.fitplans p{
  font-size: 13px;
 }
 #specs {
   margin: 20px 5px 5px 5px;
 }
 #specs h2 {
  font-size: 25px;
}
#specs h4 {
  font-size: 20px;
}
.pride h1 {
  font-size: 1.8rem;
    top: 35%;
    right: 11%;

}
.pain h1 {
font-size: 1.8rem;
top: 35%;
}
}

   @media (min-width: 250px) and (max-width:575.98px) { 

    .main-total-box div {
      width: 100%;
      padding: 10px ;
    }

    .main-total-box div strong {
      font: 600 23px/23px Gilroy-SemiBold,sans-serif;
    }
  .dot {
     display: none;
    }
  

    .main-total-box span{
      font-size: 0.9rem;
      line-height: 1px;
    }
    .service-offered{
      flex-direction: column;
    }

            #main-container{
              flex-direction: column;
              align-items: center;
            }
            .intro {
            
              text-align: center;
              padding: 20px 10px 20px 10px;
            }
            .intro h1 {
              font-size: 1rem;
            
            }
         
            .footer-content {
              margin: 0px auto;
              padding: 25px 10px;
              min-height: 230px;
          }
            
        .navbar-brand {
          font-size: 27px;
          font-weight: 600;
      }
                footer {
                  padding: 0px 0 0px 0px;
                  }
              .jumbotron {
          
                  min-height: 340px!important;
                
                padding: 5px 15px 10px 15px;
              }
              .intro h2::first-letter {
                font-size: 200%;
                color: #000;
              }
              .intro h2 {   
                font-size: 1.5rem;
              }
              .intro h2 a {
                font-size: 1rem!important;
              }
              #socialbtn {
                position: relative;
                top: 5%;
            }
            .downarrow{ 
              position: relative;
              float: left;
              left: 0%;
              top:10%;
            }
            #discount-block h2{
              font-size: 1.5rem;
            }
            .discount-block-img{
              padding: 20px;
            }
          #specs{

            margin: 20px 0px 0px 0px;
          }
          #specs .fas {
            padding: 10px;
            font-size: 40px;
          }
          #specs h2{
            font-size: 1.5rem;
          }
          .contact-heading h1 {
            font-size: 1.5rem;
        }
        .open-time{
          padding: 0px;
          padding-top: 10px;
        }
        .footer-widget h3{
            font-size: 1.30rem;
        }
      .footer-widget-content .open-time{
        padding: 0px;
      }
    h1 {
      font-size: 2.1rem;
     }
     h4 {
      font-size: 1.2rem;
  }
   .btn{
      padding: .375rem .75rem;
      font-size: 1rem;
    }
    .row-content {
      margin: 0px auto;
      padding: 10px 0px;
    }
    #discount-block p{
         font-size: 15px;
    }
    .intro{
      font-size: 15px;
    }
    .deals{
      font-size: 15px;
      text-align: center;
    }

    .carousel-nav-icon{
             display: none;
    }
    #Navbar{
      background: none ;
    }
    .navbar-collapse{
        padding: 10px;
    }
  
    .pride h1 {
      font-size: 1.1rem;
      top: 30%;
      right: 12%;
  }
  .pain h1 {
    font-size: 1.1rem;
    top: 30%;
    right: 12%;
}
.intro-p-span{
  display: none;
}
  
  }

  @media (min-width: 100px) and (max-width: 372px){
      .pride h1 {
        font-size: 0.8rem;
        top: 30%;
        right: 30%;
      }
      .pain h1 {
        font-size: 0.8rem;
        top: 30%;
        right: 30%;
      }
      .navbar-brand {
      
        margin-right: 0rem;
      }
      .intro-p-span{
        display: none;
      }
  }

  @media (min-width: 576px){
      .modal-dialog {
          max-width: 800px;
          margin: 1.75rem auto;
      }
  
  }
  @media (max-width: 390px){
    .lastelem {
       
        margin-top: 10px!important;
    }

}
  

  #myVideo {

    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
  }
.video-container{
  
    background-image: url("/src/assets/navbg3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  
}
  .video-container video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
  }

  @media (max-width: 576px){
    .main-heading {
      font-size: 25px;
    }
    .intro {
      margin: 0;
    }
    .video-container {
      background-position: left;
    }
    .navbar .main-nav{
      padding: 10px;
    }
    .main-intro-block {
      min-height: 550px;
    }
    .main-intro-block::before {
      height: 8px;
  }
   .jumbotron .Aboutcomp{
      font-size: 0.8rem;
      position: relative;
    bottom: -10px;
    }
     .css-typing p:nth-child(1) {
    
      margin: auto;
      font-size: 1rem!important;
    } 
    .css-typing p:nth-child(2) {
    
      font-size: 1rem!important;

      margin: auto;

    } 
  
    #whatsapp-block img {
      display: none;
  }
  #reservebtn a {
    margin-top: 30px;
    font-size: 0.8rem;
  }
  #whatsapp-block h2 {
    
    line-height: 2rem;
    font-size: 20px;
  }
}


.intro h2, .intro p{
  color: #fff;
}
.intro span{
  white-space: nowrap;
}
  .typewrite{
    color: #fff!important;
  }

  .middle {
    transition: .5s ease;
    position: absolute;
    bottom: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    
  }
  .middle .btn{
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    background: #ffde88;
    color: #000;
    border-width: 0.5px;
    border-radius: 4px;
  }
  
  .dot {
    font-size: 200%;

    color: #ffde88;
  }

  .slick-track{
    opacity: 1; width: 5365px; transform: translate3d(-3330px, 0px, 0px); transition: transform 500ms ease 0s;
  }
  /* .slide{
    width: 100px!important;
  } */

  .fitplans, .propServices{
    background-image: url("/src/assets/img/dotbg5.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

 
.bounce i{
 font-size: 23px;
 font-weight: 600;
 color: #000!important;
}

  
 .deals h3{
  padding: 30px 0 8px;
  position: relative;
 z-index: 100;
 
}

/* .deals h3::before {
  content: "";
  position: absolute;
  left: 8%;
  bottom: 12px;
  width: 150px;
  height: 10px;
  transform: skew(
-12deg
) translateX(-50%);
  background: rgb(59 112 125 / 55%);
} */


.ins{
  min-height: 100px;
}
  

.propServices h1{
  padding: 5px 0 8px;
  position: relative;
}

/* .propServices h1::before {
  content: "";
  position: absolute;
  bottom: 15px;
  width: 150px;
  height: 10px;
  transform: skew( 
-12deg
) translateX(-50%);
  background: rgb(59 112 125 / 55%);
} */

.GetInTouch a{
  background: #334047!important;
  color: #fff!important;
  font-size: 1rem;
  font-weight: 600;
    line-height: 1;
    border: 1px solid; 
    border-radius: 30px;
    margin-top: 10px;
 
}

.contact i{
  color: #000!important;
}

.carousel-control-next, .carousel-control-prev{
color: #000!important;
}

.carousel-control-prev {
  left: -30px;
}
.carousel-control-next {
  right:-30px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
 
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 35px;
  font-weight: 600;
  color: red;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 35px;
  font-weight: 600;
  color: red;
}

.Slidercomp{
  width: 130px!important;
  text-align: center;
  
}

.Slidercomp img{
  width: inherit;
}

@media (min-width: 150px) and (max-width: 290px)
{
.intro h1 {
    font-size: 0.8rem;
}
.css-typing p{
  font-size: 0.8rem!important;
}
}

.reg{
  font-size: 17px;
    font-weight: 500;
    position: absolute;
}

 
.icon-bar {
  display: block;
  position: fixed;
  top: 85%;
  right: 3%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 100;
}

.icon-bar a {
  display: block;
  text-align: center;

  height: 52px;
    line-height: 52px;
    width: 58px;

  padding: 0px 10px;
  transition: all 0.3s ease;
  color: #fff !important  ;
  background: #00AF54;
  font-size: 28px;
  border-radius: 33%;
}


.fa-whatsapp {
  color: #fff!important;
}

.whatspp-head{
  color: #00AF54!important;
}

@media (max-width: 575px){
 
  .icon-bar {
    display: block;
}
.navbar-nav {
  text-align: center;
  margin: 0;
}
}

@media (min-width: 576px) and (max-width: 768px)
{
  .navbar-nav {
    margin: 10px 0px 10px 45px;
}
}
